.contained{
    height: 100vh;
    margin-top: -100px;
    border: 1px solid transparent;
    /* background-image: url('../public/ab.png'); */
}
.content{
    width: 55%;
    height: 70vh;
    margin: 10% auto;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid #7c5295;
}
.titles{
    font-size: 20px;
    color: #7c5295;
}
.status{
    font-size: 15px;
    color: #7c5295;
}

@media screen and (max-width: 740px) {
    .content{
        margin-top: 20vh;
        width: 80%;
    }
}