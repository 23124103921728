.container {
    height: 100px;
    padding: 0px 50px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
  }
  .imaging{
    border: 2px solid #7c5295;
    cursor: pointer;
  }