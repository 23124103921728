html,
body {
  font-family: 'Amazon Ember';
  width: 100%;
  /* height: 100%; */
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.app{
  border: 1px solid transparent;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../public/images/iq.png');
}