.containers{
    width: 90%;
    /* border: 2px solid red; */
    margin: 20px auto;
}
.row{
    margin-top: 15px;
    border-radius: 5px;
    background-color: #cefad0 !important;
    border: 1px solid green !important;
    color: green !important;
    padding-left: 10px;
}
.input{
    height: 45px;
    width: 80%;
    border: 1px solid transparent;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    border-radius: 30px;
    padding: 0 24px;
    border: 2px solid #7c5295;
    color: #000000;
    margin-bottom: 15px;
    background-color: #ffffff;
    transition: all 0.5s ease;
}
.buttons{
    padding: 12px 25px;
    border-radius: 5px;
    border: none;
    background: #7c5295;
    color: #fff;
    cursor: pointer;
  }

